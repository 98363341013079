import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, AppBar, Tabs, Tab, Box, Divider, Typography } from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';
import ReceivedCrushes from './ReceivedCrushes';
import Cookies from 'js-cookie'; // 引入 js-cookie

const SendCrush = ({ studentClassNumber, studentStudentNumber }) => {
    console.log("Sender Class Number at render:", studentClassNumber);
    console.log("Sender Student Number at render:", studentStudentNumber);
    const [value, setValue] = useState('1');
    const [receiverClassNumber, setReceiverClassNumber] = useState('');
    const [receiverStudentNumber, setReceiverStudentNumber] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');  // 用于显示错误消息
    const csrftoken = Cookies.get('csrftoken');  // 获取 CSRF token
    console.log('CSRF Token:', csrftoken); 
    console.log({
        sender_class_number: studentClassNumber,
        sender_student_number: studentStudentNumber,
        receiver_class_number: receiverClassNumber,
        receiver_student_number: receiverStudentNumber,
        message: message
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // 清除之前的错误信息

        const formattedReceiverStudentNumber = receiverStudentNumber.padStart(2, '0');

        // 检查是否标记了自己
        if (receiverClassNumber === studentClassNumber && formattedReceiverStudentNumber === studentStudentNumber) {
            setError('不能將自己標記為暗戀對象!');
            return;
        }

        const csrftoken = Cookies.get('csrftoken');  // 从 cookies 中获取 CSRF token

        try {
            const response = await axios.post('https://crushapi.ylcsss.org/crush/send_crush/', {
                sender_class_number: studentClassNumber,
                sender_student_number: studentStudentNumber,
                receiver_class_number: receiverClassNumber,
                receiver_student_number: formattedReceiverStudentNumber,
                message: message
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                },
                withCredentials: true
            });
            if (response.data.success) {
                alert('成功標記暗戀! 當對方同时將你標記為暗戀，到時候你同佢都会知道!');
            } else {
                alert('發生錯誤: ' + response.data.message);
            }
        } catch (error) {
            console.error('Sending crush failed', error);
            alert('發生錯誤,請檢查輸入資料是否正確，以及確保輸入的資料沒有重複');
        }
    };

    const handleClassNumberChange = (e) => {
        setReceiverClassNumber(e.target.value.toUpperCase().slice(0, 2));
    };

    const handleStudentNumberChange = (e) => {
        setReceiverStudentNumber(e.target.value.slice(0, 2));
    };
    const handleBuyPermissionClick = () => {
        window.location.href = 'https://ylcsss.org/%e5%88%86%e7%8f%ad%e7%b5%90%e6%9e%9c/';  // 将 'https://example.com/buy-permission' 替换为你希望跳转的实际URL
    };

    return (
        <TabContext value={value}>
            <AppBar position="static" color="default">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="表達暗戀" value="1" />
                    <Tab label="查看暗戀名單" value="2" />
                    <Tab label="關於Secret Crush" value="3" />
                </Tabs>
            </AppBar>
            <TabPanel value="1">
                    <Typography variant="h4" align="center">
                        ❤️ Secret Crush
                    </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField 
                        label="對方的班別" 
                        value={receiverClassNumber} 
                        onChange={handleClassNumberChange} 
                        required 
                        fullWidth 
                        margin="normal" 
                        inputProps={{ maxLength: 2 }}
                    />
                    <TextField 
                        label="對方的班號" 
                        value={receiverStudentNumber} 
                        onChange={handleStudentNumberChange} 
                        required 
                        fullWidth 
                        margin="normal" 
                        inputProps={{ maxLength: 2 }}
                    />
                    <TextField 
                        label="留言(最多20字)" 
                        value={message} 
                        onChange={e => setMessage(e.target.value.slice(0, 20))} 
                        fullWidth 
                        margin="normal" 
                    />
                    {error && (
                        <Typography color="error" style={{ textAlign: 'center', marginBottom: '10px' }}>
                            {error}
                        </Typography>
                    )}
                    <Button type="submit" style={{ backgroundColor: '#f06292' }} variant="contained" fullWidth>表達暗戀!</Button>
                    <Divider style={{ margin: '20px 0' }} />
                    <Typography variant="body1" align="center">
                        如果唔知道對方的班別同學號,可以去人名表查詢
                    </Typography>
                    <Button style={{ backgroundColor: '#f06292' }} variant="contained" fullWidth onClick={handleBuyPermissionClick}>人名表</Button>
                </form>
            </TabPanel>
            <TabPanel value="2">
                <ReceivedCrushes classNumber={studentClassNumber} studentNumber={studentStudentNumber} />
            </TabPanel>
            <TabPanel value="3">
                <Typography variant="h4" align="center">
                    ❤️ Secret Crush
                </Typography>
                <Typography variant="body1" style={{ margin: '20px' }}>
                    Secret Crush 係一個俾元天同學使用的匿名暗戀平台由元天秘密網營運，你可以喺度表達暗戀，當對方同樣暗戀你嘅時候，你地就會知道對方係邊個！
                </Typography>
                <Button variant="contained" color="primary" onClick={() => { alert('更多信息！'); }}>
                    前往元天秘密網
                </Button>
            </TabPanel>
        </TabContext>
    );
};

export default SendCrush;
