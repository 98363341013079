import React, { useEffect, useState } from 'react';
import Login from './components/Login'; // Ensure the correct path
import SendCrush from './components/SendCrush'; // Ensure the correct path

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [studentId, setStudentId] = useState('');
  const [classNumber, setClassNumber] = useState('');
  const [studentNumber, setStudentNumber] = useState('');

  useEffect(() => {
    const savedStudentId = localStorage.getItem('studentId');
    const savedClassNumber = localStorage.getItem('classNumber');
    const savedStudentNumber = localStorage.getItem('studentNumber');
    if (savedStudentId && savedClassNumber && savedStudentNumber) {
      setIsLoggedIn(true);
      setStudentId(savedStudentId);
      setClassNumber(savedClassNumber);
      setStudentNumber(savedStudentNumber);
    }
  }, []);

  const handleLogin = (studentId, classNumber, studentNumber) => {
    localStorage.setItem('studentId', studentId);
    localStorage.setItem('classNumber', classNumber);
    localStorage.setItem('studentNumber', studentNumber);
    setIsLoggedIn(true);
    setStudentId(studentId);
    setClassNumber(classNumber);  
    setStudentNumber(studentNumber);  
};

  return (
    <div className="App">
      {!isLoggedIn ? (
        <Login onLogin={handleLogin} />
      ) : (
        <SendCrush 
          studentClassNumber={classNumber} 
          studentStudentNumber={studentNumber}
        />
      )}
    </div>
  );
}

export default App;
