import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Typography } from '@mui/material';
import styles from './Login.module.css';

const Login = ({ onLogin }) => {
    const [studentId, setStudentId] = useState('');
    const [classNumber, setClassNumber] = useState('');
    const [studentNumber, setStudentNumber] = useState('');

    const handleClassNumberChange = (e) => {
        const value = e.target.value.toUpperCase().slice(0, 2);  // 转为大写并限制长度为2
        setClassNumber(value);
    };

    const handleStudentNumberChange = (e) => {
        const value = e.target.value.toLowerCase().slice(0, 2);  // 转为小写并限制长度为2
        setStudentNumber(value);
    };

    const handleStudentIdChange = (e) => {
        const value = e.target.value.toLowerCase();  // 转为小写
        setStudentId(value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formattedStudentNumber = studentNumber.padStart(2, '0');
        try {
            const response = await axios.post('https://crushapi.ylcsss.org/crush/login/', {
                student_id: studentId,
                class_number: classNumber,
                student_number: formattedStudentNumber,
            });
            console.log(response.data);
            if (response.data.message === 'Login successful') {  // 根据后端实际返回的信息判断
                onLogin(studentId, classNumber, formattedStudentNumber);  // 调用 onLogin，传递学生 ID
            } else {
                alert(response.data.message || '登入失敗，請檢查輸入的信息是否正確');
            }
        } catch (error) {
            console.error('Login failed', error);
            alert('登入失敗，請檢查輸入的信息是否正確');
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <span className={styles.emoji}>❤️</span>
                <Typography variant="h4">Secret Crush</Typography>
            </div>
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.inputs}>
                    <TextField
                        label="班別"
                        value={classNumber}
                        onChange={handleClassNumberChange}
                        required
                        className={styles.input}
                    />
                    <TextField
                        label="班號"
                        value={studentNumber}
                        onChange={handleStudentNumberChange}
                        required
                        className={styles.input}
                    />
                </div>
                <div>
                    <TextField
                        label="學生編號(s20xx01001)"
                        value={studentId}
                        onChange={handleStudentIdChange}
                        required
                        className={styles.fullWidthInput}
                    />
                </div>
                <div>
                    <Button type="submit" variant="contained" className={styles.submitButton}>
                        登入！
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default Login;
