import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Button, Box, Paper, Divider } from '@mui/material';

function ReceivedCrushes({ classNumber, studentNumber }) {
    const [crushes, setCrushes] = useState([]);
    const [sentCrushes, setSentCrushes] = useState([]);
    const [hasSpecialPermission, setHasSpecialPermission] = useState(false);
    const [showSpecialOffer, setShowSpecialOffer] = useState(false);
    const [crushCount, setCrushCount] = useState(0);
    const handleBuyPermissionClick = () => {
        window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfCpTc5i9ZjYhjBd3qUJFf-PP8kdPc_TFnyy4O0Fl0CGSqG2Q/viewform?usp=sf_link';  // 将 'https://example.com/buy-permission' 替换为你希望跳转的实际URL
    };

    useEffect(() => {
        axios.get(`https://crushapi.ylcsss.org/crush/received_crushes/?class_number=${classNumber}&student_number=${studentNumber}`)
            .then(response => {
                console.log("API Response:", response.data); 
                setCrushes(response.data.crushes);
                setCrushCount(response.data.count);
                setShowSpecialOffer(response.data.special_offer);
                setHasSpecialPermission(response.data.has_special_permission);
            })
            .catch(error => console.log('Error fetching crushes', error));

        axios.get(`https://crushapi.ylcsss.org/crush/sent_crushes/?class_number=${classNumber}&student_number=${studentNumber}`)
            .then(response => {
                setSentCrushes(response.data.crushes);
            })
            .catch(error => console.log('Error fetching sent crushes', error));
    }, [classNumber, studentNumber]);

    return (
        <div>
            <Typography variant="h5" align="center" gutterBottom>收到的暗戀</Typography>
            <Divider />
            {crushCount > 0 && (
                <Box mb={2} textAlign="center">
                    <Typography variant="body1">想單方面知道誰暗戀你嗎？使用 $20 購買特殊權限可以無需向其他表達暗戀即可知道邊個暗戀你!</Typography>
                    <Button variant="contained" color="primary" onClick={handleBuyPermissionClick}>立即購買！</Button>
                </Box>
            )}
            <Typography variant="h6" align="center" gutterBottom>暗戀你的人數: {crushCount}</Typography>
            {crushes.map((crush, index) => (
                <Paper key={index} style={{ margin: '10px', padding: '10px', border: (crush.mutual || hasSpecialPermission) ? '2px solid #f06292' : 'none' }}>
                    <Typography color={(crush.mutual || hasSpecialPermission) ? 'red' : 'inherit'}>
                        留言: {crush.message}
                        {crush.mutual ? `  | 配對成功！對方係: ${crush.sender_class_number} ${crush.sender_student_number}` : ''}
                        {!crush.mutual && hasSpecialPermission ? `  | 對方係: ${crush.sender_class_number} ${crush.sender_student_number}` : ''}
                    </Typography>
                </Paper>
            ))}
            <Typography variant="h5" align="center" gutterBottom>我表達的暗戀</Typography>
            <Divider />
            {sentCrushes.map((crush, index) => (
                <Paper key={index} style={{ margin: '10px', padding: '10px' }}>
                    <Typography>
                        發送俾: {crush.receiver_class_number} {crush.receiver_student_number} | 留言: {crush.message}
                    </Typography>
                </Paper>
            ))}
            <Typography variant="caption" align="center" display="block" gutterBottom>
                {hasSpecialPermission ? "你擁有特殊權限，可以查看所有暗戀你的人!" : "YLCSSS 2024 ©"}
            </Typography>
        </div>
    );
}

export default ReceivedCrushes;